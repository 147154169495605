<template>
  <main class="content">
    <div class="inner content-grid">
      <div class="left">
        <page-heading heading="Messages" icon="messages-o.png"
          para="See messages from the system administrator below."></page-heading>
        <template v-if="error_message">
          <error-panel :content="error_message"></error-panel>
        </template>
        <template v-if="!error_message && messages && messages.length < 1">
          <info-panel content="There are no messages to display at this time."></info-panel>
        </template>
        <template v-if="!error_message && messages && messages.length > 0">
          <info-panel content="To view the full message, click the row or title."></info-panel>
          <table class="messages-table multiple" v-if="!viewing">
            <thead>
              <tr>
                <td class="td-all">
                  <input type="checkbox" aria-label="Select all" v-model="all_selected" @change="selectAll"
                    v-tooltip="'Select all'" />
                </td>
                <td class="td-date">DATE</td>
                <td colspan="2" class="padded">
                  MESSAGE
                  <div v-if="selected.length" class="messages-bulkactions">
                    <a href @click.prevent="showDeleteMultipleModal"><span>Delete</span> <img
                        src="/images/icons/trash.png" alt="" /></a>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr class="selectable" tabindex="0" @keyup.enter="viewMessage(msg)" @click.self=" viewMessage(msg)"
                :key="i" v-for="(msg, i) in messages" :class="msg.message_read ? 'tr-read' : ''">
                <td @click.self="viewMessage(msg)"><input :value="msg.id" aria-label="Select Message" v-model="selected"
                    type="checkbox" /></td>
                <td @click.self="viewMessage(msg)">{{ formatDate(msg.timestamp) }}</td>
                <td @click.self="viewMessage(msg)">{{ msg.subject }}</td>
                <td @click.self="viewMessage(msg)" class="read-status">
                  <img @click.self="viewMessage(msg)" v-if="msg.message_read" src="/images/icons/envelope-open.png"
                    alt="Read" />
                  <img @click.self="viewMessage(msg)" v-if="!msg.message_read" src="/images/icons/envelope-closed.png"
                    alt="Unread" />
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <!-- <div class="list-more">
          <a href><img src="/images/icons/right-col-link-arrow.png" alt="more" /> View more...</a>
        </div> -->
        <template v-if="viewing">
          <table class="messages-table single">
            <thead>
              <tr>
                <td>Title</td>
                <td colspan="2" class="padded">
                  {{ viewing.subject }}
                  <div class="messages-bulkactions">
                    <a href @click.prevent="showDeleteSingleModal(viewing)"><span>Delete</span> <img
                        src="/images/icons/trash.png" alt="" /></a>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Date:</td>
                <td>{{ formatDateAndTime(viewing.timestamp) }}</td>
              </tr>
              <tr>
                <td>Message:</td>
                <td v-html="viewing.content" class="messagebody"></td>
              </tr>
            </tbody>
          </table>
          <div class="actions">
            <button @click="viewing = null" class="negative">
              Close <img src="/images/icons/button-icon-cancel.png" alt="Close" />
            </button>
          </div>
        </template>
      </div>
      <right-column></right-column>
    </div>

    <modal :scrollable="true" height="auto" name="delete-single" width="90%">
      <a @click.prevent="$modal.hide('delete-single')" class="modal-close" href aria-label="Close">
        <font-awesome-icon icon="times" aria-hidden="true" role="presentation" />
      </a>
      <h2>Please confirm</h2>
      <p>Are you sure you want to delete this message?</p>
      <p class="error" v-if="delete_single_error_message">{{ delete_single_error_message }}</p>
      <div class="actions">
        <button @click="deleteSingle"><font-awesome-icon icon="check" aria-hidden="true" role="presentation" />
          Yes</button>
        <button class="negative" @click="$modal.hide('delete-single')"><font-awesome-icon icon="times"
            aria-hidden="true" role="presentation" /> No</button>
      </div>
    </modal>

    <modal :scrollable="true" height="auto" name="delete-multiple" width="90%">
      <a @click.prevent="$modal.hide('delete-multiple')" class="modal-close" href aria-label="Close">
        <font-awesome-icon icon="times" aria-hidden="true" role="presentation" />
      </a>
      <h2>Please confirm</h2>
      <p>Are you sure you want to delete these messages?</p>
      <p class="error" v-if="delete_multiple_error_message">{{ delete_multiple_error_message }}</p>
      <div class="actions">
        <button @click="deleteSelected"><font-awesome-icon icon="check" aria-hidden="true" role="presentation" />
          Yes</button>
        <button class="negative" @click="$modal.hide('delete-multiple')"><font-awesome-icon icon="times"
            aria-hidden="true" role="presentation" /> No</button>
      </div>
    </modal>
  </main>
</template>

<script>
import api from "@/services/api"
import dateUtil from "date-and-time"

export default {
  data() {
    return {
      error_message: "",
      all_selected: false,
      selected: [],
      messages: [],
      viewing: null,

      msg_to_delete: {},
      delete_single_error_message: "",
      delete_multiple_error_message: ""
    }
  },
  created() {
    this.getItems()
  },
  methods: {
    getItems() {
      this.error_message = ""
      this.loading = true
      api
        .request("get", "message")
        .then(res => {
          this.$store.commit("setMessages", JSON.parse(JSON.stringify(res)))
          this.messages = JSON.parse(JSON.stringify(this.$store.state.messages))
        })
        .catch(() => {
          this.error_message = "There has been an error"
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectAll() {
      if (this.all_selected) this.selected = this.messages.map(m => m.id)
      else this.selected = []
    },
    viewMessage(msg) {
      this.viewing = msg

      msg.message_read = true

      this.$store.commit("setMessages", this.messages)
      api.request("patch", "message?id=" + msg.id).catch(() => { })
    },
    showDeleteSingleModal(item) {
      this.msg_to_delete = item
      this.$modal.show("delete-single")
    },
    deleteSingle() {
      this.delete_single_error_message = ""

      api
        .request("delete", "message?id=" + this.msg_to_delete.id)
        .then(() => {
          this.viewing = null
          this.messages = this.messages.filter(m => m.id != this.msg_to_delete.id)
          this.$store.commit("setMessages", this.messages)

          this.$modal.hide("delete-single")
        })
        .catch(() => {
          this.delete_single_error_message = "There was an error, please try again"
        })
    },
    showDeleteMultipleModal(item) {
      this.$modal.show("delete-multiple")
    },
    deleteSelected() {
      this.delete_multiple_error_message = ""

      api
        .request("delete", "message?ids=" + JSON.stringify(this.selected))
        .then(() => {
          this.all_selected = false
          this.viewing = null

          this.selected.forEach(s => {
            this.messages = this.messages.filter(m => m.id != s)
          })

          this.selected = []
          this.$store.commit("setMessages", this.messages)

          this.$modal.hide("delete-multiple")
        })
        .catch(() => {
          this.delete_single_error_message = "There was an error, please try again"
        })
    },
    formatDate(d) {
      if (!d.getTime) d = new Date(d)

      return dateUtil.format(d, "DD/MM/YYYY")
    },
    formatDateAndTime(d) {
      if (!d.getTime) d = new Date(d)

      return dateUtil.format(d, "DD/MM/YYYY HH:mm")
    }
  }
}
</script>

<style scoped>
input:focus-visible {
  outline: 2px solid #CB4E0B !important;
  outline-offset: 2px;
}

.selectable:focus-visible {
  outline: 2px solid #CB4E0B !important;
  outline-offset: 2px;
}

.messages-table {
  width: 100%;
  border-collapse: collapse;
}

.messages-table td {
  padding: 2rem;
}

.messages-table thead td {
  background: #f3f3f3;
  font-weight: bold;
  position: relative;
  border-bottom: 2px solid #5f6062;
}

.messages-table.single {
  border-bottom: 1px solid #f3f3f3;
}

.messages-table input[type="checkbox"] {
  transform: scale(1.2);
  cursor: pointer;
}

.messages-table tbody td {
  padding: 1.5rem 2rem;
}

.messages-table.single tbody td {
  vertical-align: top;
}

.messages-table thead td.padded {
  padding-right: 5rem !important;
}

.messages-table>>>p {
  margin-bottom: 1.5rem;
}

.messages-table.multiple tbody tr:nth-child(even) td {
  background: #f3f3f3;
}

.messages-table tbody tr:hover td {
  cursor: pointer;
}

.messages-table tbody tr:hover td:nth-child(1):hover {
  cursor: default;
}

.messages-bulkactions {
  position: absolute;
  right: 2rem;
  top: 55%;
  transform: translateY(-50%);
  font-weight: normal;
  white-space: nowrap;
}

.messages-bulkactions a {
  margin-left: 2rem;
  opacity: 0.8;
}

.messages-bulkactions a:hover {
  opacity: 1;
}

.messages-bulkactions a.bordered {
  padding-left: 2rem;
  border-left: 1px solid rgb(156, 156, 156);
}

.messages-bulkactions a img {
  height: 3rem;
  position: relative;
  vertical-align: middle;
  margin-left: 0.25rem;
  margin-top: -0.25rem;
}

.td-all {
  width: 4rem;
}

.td-date {
  width: 12rem;
}

.read-status {
  text-align: right;
}

.read-status img {
  height: 3rem;
}

.tr-read {
  color: #757575;
}

.actions {
  text-align: center;
}

.messages-table>>>h1,
.messages-table>>>h2,
.messages-table>>>h3 {
  color: #333 !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 1.5rem !important;
  border: 0 !important;
}

.messages-table>>>p {
  margin: 1.5rem 0;
}

.messages-table>>>ul,
.messages-table>>>ol {
  padding: 1rem 0 1rem 3rem;
}

.messagebody>>>p:first-child,
.messagebody>>>h1:first-child,
.messagebody>>>h2:first-child,
.messagebody>>>h3:first-child {
  margin-top: 0 !important;
}

@media screen and (max-width: 600px) {
  .messages-bulkactions a span {
    display: none;
  }

  .messages-bulkactions a.bordered {
    padding-left: 0;
    border-left: 0;
    margin-left: 1rem;
  }

  .read-status {
    height: 2rem;
    display: none;
  }

  .actions button {
    font-size: 1.8rem;
  }

  .actions button img {
    height: 1.5rem;
    margin-bottom: 0;
  }
}
</style>
